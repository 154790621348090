<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">NHF</h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div v-if="!showError">
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div class="mx-4 mt-3">
          <div class="w-full flex mt-2 gap-5">
            <Card
              class="w-auto flex flex-grow text-white px-3 pt-6"
              style="background: #3454d1 !important"
            >
              <div class="flex flex-grow">
                <div class="flex flex-grow">
                  <Icon class-name="text-white " icon-name="union" size="m" />

                  <div class="flex flex-col flex-grow ml-4">
                    <span class="font-black">Total NHF deductions</span>
                    <span class="text-lg font-bold">
                      {{ convertToCurrency(nhfData.totalNhfDeduction) }}</span
                    >
                  </div>
                </div>

                <div class="border bg-white w-0 mx-4 mb-4" />

                <div class="flex flex-grow">
                  <Icon class-name="text-white " icon-name="union" size="m" />
                  <div class="flex flex-col flex-grow ml-4">
                    <span class="font-black">Total Remitted</span>
                    <span class="text-lg font-bold">{{
                      convertToCurrency(nhfData.totalRemitted)
                    }}</span>
                  </div>
                </div>

                <div class="border bg-white w-0 mx-4 mb-4" />

                <div class="flex flex-grow">
                  <Icon class-name="text-white " icon-name="union" size="m" />
                  <div class="flex flex-col flex-grow ml-4">
                    <span class="font-black">Total Outstanding</span>
                    <span class="text-lg font-bold">{{
                      convertToCurrency(nhfData.totalOutstanding)
                    }}</span>
                  </div>
                </div>
              </div>
            </Card>

            <Card
              class="w-auto px-3 flex text-white"
              style="
                background: #13b56a !important;
                box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06),
                  0px 1px 3px rgba(8, 7, 8, 0.1);
                border-radius: 8px;
              "
            >
              <Icon icon-name="employee_payrun" class-name="ml-4" size="m" />

              <div class="flex flex-col my-8 ml-6">
                <span class="font-semibold uppercase text-xs"
                  >NHF employees</span
                >
                <span class="font-bold text-lg pt-2">{{
                  nhfData.employeeCount
                }}</span>
              </div>
            </Card>
          </div>

          <div class="mt-2 w-full gap-5" v-if="nhfRunData.length > 0">
            <div class="w-full mt-4 gap-5">
              <Card class="flex flex-col w-full p-3">
                <h1 class="font-bold text-lg my-4">NHF Summary</h1>
                <div class="w-full flex justify-between">
                  <span class="w-4/6 text-base"
                    >This is an overview of your NHF summary.</span
                  >

                  <Card
                    class="
                      p-2
                      h-10
                      flex
                      justify-center
                      items-center
                      cursor-
                      W-2/6
                    "
                  >
                    <Icon
                      class-name="text-darkPurple mx-auto self-center mr-2"
                      size="xs"
                      icon-name="calendar"
                    />
                    | <span class="ml-2 text-base">Year to Date</span>
                    <Icon
                      class-name="text-darkPurple mx-auto self-center ml-2"
                      size="xms"
                      icon-name="caret-down"
                    />
                  </Card>
                </div>
              </Card>
            </div>

            <div class="w-full mt-10">
              <sTable
                :headers="nhfHeader"
                :items="nhfRunData"
                aria-label="pension table"
                class="w-full mt-1"
                :has-checkbox="false"
                :loading="isLoader"
                :pagination-list="metaData"
                @page="handlePage($event)"
                @itemsPerPage="handleItemsPerPage($event)"
                page-sync
                has-number
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.status">
                    <Badge
                      label="Remitted"
                      variant="primary"
                      background-color="rgba(19, 181, 106, 0.08)"
                      color="#13B56A"
                      style="
                        padding: 8px;
                        height: 33px;
                        font-weight: 600;
                        font-size: 14px;
                      "
                      v-if="item.data.status === 'paid'"
                    />
                    <Badge
                      label="Outstanding"
                      variant="primary"
                      background-color="rgba(234, 60, 83, 0.08)"
                      color="#EA3C53"
                      style="
                        padding: 8px;
                        height: 33px;
                        font-weight: 600;
                        font-size: 14px;
                      "
                      v-else
                    />
                  </div>
                  <div v-else-if="item.id">
                    <div
                      @click="handleView(item.data.payrunNo)"
                      class="cursor-pointer h-8 flex"
                    >
                      <icon
                        icon-name="icon-eye"
                        size="xsm"
                        class="-mt-1 -ml-2 text-blueCrayola"
                        style="padding: 3px 10px; width: 36px; height: 36px"
                      />
                    </div>
                  </div>
                </template>
              </sTable>
            </div>
          </div>

          <div v-else class="mt-10">
            <div
              class="w-full h-auto flex flex-col"
              style="align-items: center"
            >
              <div
                class="w-5/12 h-auto mt-5 mb-5 flex"
                style="justify-content: center"
              >
                <icon
                  icon-name="illustration_approval"
                  class-name="icon-Size mb-4 mt-4 text-white"
                  size="l"
                  style="width: 50%"
                />
              </div>
              <div
                class="w-6/12 h-auto text-center"
                style="width: 610px; line-height: 19px"
              >
                <p class="font-black text-lg text-flame">No Data Available</p>
                <p class="mt-5 mb-5 tracking-wide">
                  There is no data to show currently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import ErrorPage from "@/modules/Admin/error403";
import Card from "@/components/Card";

export default {
  name: "NHF",
  components: {
    Card,
    Badge,
    ErrorPage,
    Breadcrumb,
    STable,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "Payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Compliance",
          href: "Compliance",
          id: "Compliance",
        },
        {
          disabled: false,
          text: "NHF",
          href: "NHF",
          id: "NHF",
        },
      ],
      nhfHeader: [
        { title: "Payrun Cycle", value: "payrunCycle" },
        { title: "Payrun Date", value: "payrunDate" },
        { title: "Payrun Id No", value: "payrunNo" },
        { title: "No of Employees", value: "employeesCount" },
        { title: "Total NHF Deducttions", value: "nhfDeductions" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      metaData: {},
      pageNumber: null,
      itemsPerPage: null,
      isLoader: true,
      loading: true,
      nhfRunData: [],
      showError: false,
      nhfData: {
        totalNhfDeduction: 0,
        totalRemitted: 0,
        totalOutstanding: 0,
        employeeCount: 0,
      },
    };
  },
  methods: {
    async handleView(payrunNo) {
      try {
        await this.$handlePrivilege("nhf", "viewPayCycleDetails");
        this.$router.push({
          name: "ViewEmployeesNHF",
          params: { id: payrunNo },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    handlePage(pageNumber) {
      this.pageNumber = pageNumber;
      this.queryUpdate();
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.pageNumber
        ? `?page=${this.pageNumber}`
        : `?page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const filterQuery = `${pageNumber}${itemPage}`;
      this.isLoader = true;
      this.getNHF(filterQuery);
    },

    getNHF(filters) {
      this.$_getNHF(filters)
        .then((response) => {
          const payrunData = response.data.payruns;

          this.nhfData.totalNhfDeduction =
            response.data.summary.totalNhfDeduction;
          this.nhfData.totalRemitted = response.data.summary.totalRemitted;
          this.nhfData.totalOutstanding =
            response.data.summary.totalOutstanding;
          this.nhfData.employeeCount = response.data.summary.employeeCount;

          this.metaData = response.data.meta;

          this.nhfRunData = payrunData.map((data) => ({
            id: data.payrun.id,
            payrunCycle: `${this.$DATEFORMAT(
              new Date(`${data.payrun.year}-${data.payrun.month}-01`),
              `MMMM yyyy`
            )} Cycle`,
            payrunDate:
              data.payrun.payrollStatus === "paid"
                ? `${this.$DATEFORMAT(
                    new Date(`${data.payrun.paidAt}`),
                    `MMMM dd, yyyy`
                  )}`
                : `${this.$DATEFORMAT(
                    new Date(this.resetDate(`${data.payrun.payDate}`)),
                    `MMMM dd, yyyy`
                  )}`,
            payrunNo: data.payrun.payrunNo,
            employeesCount: data.employeeCount,
            nhfDeductions: `${this.convertToCurrency(data.totalNhfAmount)}`,
            status: `${data.payrun.nfhStatus}`,
          }));

          this.loading = false;
          this.isLoader = false;
        })
        .catch(() => {
          this.loading = false;
          this.isLoader = false;
        });
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("nhf", "viewRegister");
      this.showError = false;
      this.getNHF("");
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
